import React from "react"
import Navbar from "./Navbar.js"
import Footer from "./Footer"
import { ThemeProvider } from "styled-components"
import { lightTheme } from "./Themes.js"
import { GlobalStyles } from "./GlobalStyles.js"
import SideBar from "../components/SideBar"

const Layout = ({ children }) => {
  //SideBar
  const [isOpen, setIsOpen] = React.useState(false)
  const toggleSidebar = () => {
    setIsOpen(!isOpen)
  }

  return (
    <ThemeProvider theme={lightTheme}>
      <>
        <GlobalStyles />
        <Navbar toggleSidebar = {toggleSidebar}/>
        <SideBar SideBar isOpen={isOpen} toggleSidebar={toggleSidebar} ></SideBar>
        {children}
        <Footer />
      </>
    </ThemeProvider>
  )
}

export default Layout
