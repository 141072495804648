import React from "react"
import Logo from "./Logo"
import Links from '../constants/Links'
import styled from "styled-components"
import { FaBars } from "react-icons/fa"

const Navbar = ({ toggleSidebar, theme, toggleTheme }) => {
  return (
    <>
      <Nav>
        <NavCenter>
          <NavHeader>
            <NavLogo />
            {/* Coming from Layout. When Clicked Toggle Sidebar*/}
            <ToggleButton onClick={toggleSidebar}>
                <IconDiv><FaBars /></IconDiv>
                <MenuText>Menu</MenuText>
            </ToggleButton>
          </NavHeader>
          <Links></Links>
        </NavCenter>
        {/* Props coming from layout, passed to the toggle Button in Navbar*/}
      </Nav>
    </>
  )
}

const NavLogo = styled(Logo)`
  margin-bottom: 4rem;
  margin-left: 5rem;
`

const Nav = styled.nav`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  z-index: 200;
  /* Navbar Color */
`
const NavCenter = styled.section`
  width: 90vw;
  margin: 0 auto;
  @media screen and (min-width: 982px) {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
  }
`
const NavHeader = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
`


const ToggleButton = styled.button`
  display: inline-block;
  background: transparent;
  border-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 5px;
  margin:0;
  padding-top: 0rem;
  padding-bottom: 0rem;
  height: 3rem;
  cursor: pointer;
  transition: ${({ theme }) => theme.transition};
  &:hover {
    color: ${({ theme }) => theme.colorPrimary2};
  }
  

  @media screen and (min-width: 982px) {
    display: none;
    font-size: 2rem;
  }
`

const MenuText = styled.p`
  letter-spacing: 0;
  margin: auto;
  display: inline-block;
  /* vertical-align: middle; */
  width: calc(100% - 1px - 15px);
  padding:0;
  color: ${({ theme }) => theme.colorPrimary5};

`

const IconDiv = styled.div`
  margin: auto;
  display: inline-block;
  vertical-align: middle;
  padding-top:.1rem;
`
export default Navbar