import React from "react"
import { graphql, useStaticQuery} from "gatsby"
import Image from "gatsby-image"
import styled from "styled-components"
import { Link } from "gatsby-plugin-intl"


const LogoW = styled.section`
  margin-left:4rem;
  @media screen and (min-width: 768px) {
    width: 115px;
    margin-bottom: 0.375rem;
  }
  @media screen and (max-width: 799px) {
    width: 115px;
    margin-bottom: 0.375rem;
    align-items: left;
    margin-left:0rem;
  }
`

const Logo = () => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "liberacion-online.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <LogoW as={Link} to="/">
      <LogoImage fluid={data.file.childImageSharp.fluid} alt="logo"></LogoImage>
    </LogoW>
  )
}

//Filter for Dark Mode
const LogoImage = styled(Image)`
  -webkit-filter: ${({ theme }) => theme.webKitFilter};
  filter: ${({ theme }) => theme.imageFilter};
  /* Changing the Logo Positioning*/

  @media screen and (min-width: 768px) {
  top: 0rem;
  /* left: 4rem; */
  /* left: 60%; */

  }
  @media screen and (max-width: 768px) {
    /* top: 0rem;
  left:0; */
  }
`

export default Logo