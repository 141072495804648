import React from "react"
import { v4 as uuidv4 } from "uuid"
import styled from "styled-components"
import { keyframes } from "styled-components"
import { FormattedMessage, Link } from "gatsby-plugin-intl"
import { IntlContextConsumer, changeLocale, useIntl } from "gatsby-plugin-intl"
import DonateButton from '../constants/DonateButton'
import SpanishFlag from "../assets/ES.svg";
import AmericanFlag from '../assets/US.svg';


let seminarios = <FormattedMessage id="sems" />
let nosotros = <FormattedMessage id="nos" />
let testimonios = <FormattedMessage id="test" />
// let libros = <FormattedMessage id="libs" />
let articulos = <FormattedMessage id="art" />
const data = [
  {
    id: uuidv4(),
    text: "Home",
    url: "/",
  },
  {
    id: uuidv4(),
    text: seminarios,
    url: "/seminarios/",
  },
  {
    id: uuidv4(),
    text: nosotros,
    url: "/nosotros/",
  },
  {
    id: uuidv4(),
    text: testimonios,
    url: "/testimonios/",
  },
  // {
  //   id: uuidv4(),
  //   text: libros,
  //   url: "/libros/",
  // },
  {
    id: uuidv4(),
    text: articulos,
    url: "/articulos/",
  },
]
const temporalLinks = data.map(link => {
  if (link.content) {
    return link.content;
  }
  return (
    <li key={link.id}>
      <Link to={link.url}>{link.text}</Link>
    </li>
  )
})

const Links = ({ sideLinks, toggleAnimation }) => {
  const intl = useIntl()
  const { locale } = intl;
  return func1(sideLinks, toggleAnimation, locale)
}

function func1(sideLinks, toggleAnimation, locale) {
  const languageName = {
    en: "EN",
    es: "ES"
  }
 
  if (sideLinks === "sidebar") {
    if (toggleAnimation) {
      return <SideLinksAnimations>{temporalLinks}<Don><DonateButton locale={locale} /></Don><Languages languageName={languageName} /></SideLinksAnimations>
    }
  }
  return <NavLinks>{temporalLinks}<Don><DonateButton locale={locale} /><Languages languageName={languageName} /></Don></NavLinks>
}




export const Languages = ({ languageName }) => {
  return (
    <>
      <LangDiv>
        <IntlContextConsumer>
          {({ languages, language: currentLocale }) =>
            languages.map(language => (
              <a
                key={language}
                onClick={() => changeLocale(language)}
                style={{
                  color: currentLocale === language ? `cyan` : `black`,
                  marginRight: '.5rem',
                  cursor: `pointer`,
                  width: '100%',
                }}
              >
                {languageName[language]}
                {language === 'en' ? <AmericanFlag/>: <SpanishFlag/>}  
              </a>
              
            ))
          }
        </IntlContextConsumer>
      </LangDiv>
    </>
  )
}



const Don = styled.div`
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    justify-content: space-between;
 @media screen and (min-width: 982px) {
  display: flex;
  justify-content:center;
  align-items: center;
  margin-left: .2rem;
  margin-right: 1.5rem;
  margin-top: 0;
  margin-bottom: 0;
 }
`

const LangDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: large;
  font-weight: bold;
  margin-top: 2rem;
  margin-left: 0rem;
  width: 20%;

  a {
    margin: .5rem;
  }

@media screen and (min-width: 982px) {
  margin-left: 2rem;
  margin-top: 0;
  margin-bottom: 0;
  align-items:center;
  flex-direction: row;
  justify-content: center;

}
`
const SideLinks = styled.ul`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    padding: 0;
  li {
    opacity: 0;
  }
  li a {
    display: block;
    text-align: center;
    font-family: ${({ theme }) => theme.secondaryFont};
    text-transform: capitalize;
    color: ${({ theme }) => theme.text};
    letter-spacing: ${({ theme }) => theme.spacing};
    margin-top: 1rem;
    font-size: 2rem;
    transition: ${({ theme }) => theme.transition};
    border-radius: ${({ theme }) => theme.radius};
  }
  li a:hover {
    background: ${({ theme }) => theme.colorPrimary5};
    color: ${({ theme }) => theme.text};
  }

  @media screen and (min-width: 982px) {
     flex-direction: row;
     justify-content: center;
}
`
  /* Animations */

const slideRight = keyframes`
 0% {
    transform: translateX(-200px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`

const SideLinksAnimations = styled(SideLinks)`
  li {
    opacity: 0;
    animation: ${slideRight} 0.5s ease-in-out 0.3s forwards;
  }
  li:nth-of-type(1) {
    animation-delay: 0.25s;
  }
  li:nth-of-type(2) {
    animation-delay: 0.5s;
  }
  li:nth-of-type(3) {
    animation-delay: 0.75s;
  }
  li:nth-of-type(4) {
    animation-delay: 1s;
  }
  li:nth-of-type(5) {
    animation-delay: 1.25s;
  }
  li:nth-of-type(6) {
    animation-delay: 1.35s;
  }
  li:nth-of-type(7) {
    animation-delay: 1.45s;
  }
`

const NavLinks = styled.ul`
    align-items: center;
    justify-content: center;

  li {
    opacity: 1;
    animation: slideRight 0.5s ease-in-out 0.3s forwards;
  }
  display: none;
  @media screen and (min-width: 982px) {
    display: flex;
    justify-content: flex-end;
    list-style-type: none;
    li {
      margin-right: 2rem;
    }
    a {
      font-family: ${({ theme }) => theme.secondaryFont};
      color: ${({ theme }) => theme.colorGrey1};
      font-weight: bold;
      font-size: large;
      letter-spacing: 0.1rem;
      transition: ${({ theme }) => theme.transition};
      /* padding: 0.5rem 0; */
      color: ${({ theme }) => theme.text};
    }
    a:hover {
      color: ${({ theme }) => theme.colorPrimary5};
      box-shadow: 0px 2px ${({ theme }) => theme.colorPrimary5};
    }
  }
`

export default Links