import React from "react"
import styled from "styled-components"
import { injectIntl } from "gatsby-plugin-intl"

const DonateBtn = ({ locale }) => {
  console.log(locale)
  return (
    <>
      {locale === "es" ? (
        <DonateButton>
          <DonateATag href="https://www.paypal.com/donate?business=B45UJVYP8FCJN&no_recurring=0&currency_code=USD">
            Donar
          </DonateATag>
        </DonateButton>
      ) : (
        <DonateButton>
          <DonateATag href="https://www.paypal.com/donate?business=B45UJVYP8FCJN&no_recurring=0&currency_code=USD">
            Give
          </DonateATag>
        </DonateButton>
      )}
    </>
  )
}

const DonateButton = styled.button`
  background-color: yellow;
  text-transform: capitalize;
  margin-top: 2rem;
  justify-content: center;

  @media screen and (min-width: 982px) {
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
  }
`
const DonateATag = styled.a`
  @media screen and (min-width: 982px) {
    &:hover {
      box-shadow: none !important;
    }
    box-shadow: none;
  }
`

export default injectIntl(DonateBtn)
